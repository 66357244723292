//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import detail from "@/lib/data-service/flight/buyer_dom_white_flight_stop";
export default {
  data() {
    return {
      stopDetail: {}
    };
  },
  props: {
    flightNo: {
      type: String,
      default: ""
    },
    stopNum: {
      type: Number,
      default: 0
    },
    flightTime: {
      type: String,
      default: ''
    }
  },
  watch: {
    stopNum(val) {
      console.log(val)
      if (this.stopNum > 0) {
        this.getDetail();
      }
    }
  },
  created() {
    if (this.stopNum > 0 && this.flightNo && this.flightTime) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      if (Object.keys(this.stopDetail).length > 0) return;
      // let formData = this.$parent.$parent;
      let data = {
        date: this.flightTime,
        flightNo: this.flightNo,
        supplierType: 1
      };
      detail(data).then(res => {
        this.stopDetail = res.result.stopInfos || [];
      });
    }
  }
};
