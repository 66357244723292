// buyer_dom_white_ticketVoucherTypeEnumList
const __request = require(`./__request/__request_contentType_json`);
const buyer_dom_white_ticketVoucherTypeEnumList = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix:'/buyer/dom/white/ticketVoucherTypeEnumList',
    data:data,
    // enable_error_alert: false // 临时隐藏
  }

  return __request(pParameter)
}
export default buyer_dom_white_ticketVoucherTypeEnumList
