const __request = require(`../__request/flight-search/__request_contentType_json`);

// 国际机票航空公司
export default (data) => {
let pParameter = {
    method: 'post',
    urlSuffix: '/buyer/dom/white/rule',
    data:data
  }

  return __request(pParameter)
}

